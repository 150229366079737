.site-header {
  @include group;
  display: flex;
  align-items: center;
  padding: 1.2em $margin .6em;
  text-align: center;

  @media (min-width: $bp-medium) {
  }

  .site-title {
    margin: 0 auto 0 0;
    padding: 0;
    max-width: 60%;

    @media (min-width: $bp-medium) {
      max-width: 10em;
    }
  }

  .site-title a {
    margin: 0;
    padding: 0;
    opacity: 1;
    transition: opacity 0.05s ease-in-out;
  }

  .site-title a:hover,
  .site-title a:focus {
    opacity: 0.7;
  }

  .site-title a svg {
    width: 100%;
    height: auto;
  }

  .header-contact {
    transform: translateY(-.3em);
    
    a, a:visited {
      font-family: "Rubik", sans-serif;
      font-size: 1.2em;
      font-weight: bold;
      color: $color__black;
      transition: opacity .05s ease-in-out;
    }
    a:hover, a:target {
      opacity: .7;
    }
  }

}

.page-title {
  font-size: 1.4em;
  padding: 0 2%;
  text-align: center;

  @media (min-width: 800px) {
    font-size: 1.8em;
    padding: 0 14%;
  }
}

