.hero {
  background-color: $color__brown;
  background: linear-gradient(275.71deg, #EBDFC9 22.14%, #E1CEAC 89.07%), #EBDFC9;
  padding: 0;
  overflow: hidden;
  margin-bottom: 4em;

  .hero-inside {
    margin: 0 auto;
    max-width: $width__desktop;
    position: relative;
    width: $width__mobile;
  }

  .hero-text {
    max-width: 20em;
    padding: 2em 0 3em;

    @media (min-width: $bp-medium) {
      padding: 2em 0;
    }

    a, a:visited {
      border-bottom: 2px solid $color__green;
      color: $color__black;
      display: inline-table;
      padding-bottom: .05em;
      opacity: 1;
      transition: opacity .05s ease-in-out;
    }
    a:hover, a:target {
      opacity: .8;
    }
  }

  .hero-illustration {
    position: absolute;
    width: 50%;
    right: 0;
    bottom: -10%;
    max-width: 30em;

    svg {
      max-width: 100%;
      height: auto;
    }  
  }
}